@import "../../sass/global";

.quotes {
  margin-left: auto; }

.quote {
  background: $dark-black;
  display: grid;
  grid-template-columns: 1fr 150px;
  padding: $module-padding;
  margin-bottom: 2em;
  width: 100%;

  @include tablet {
    margin: 1rem 0; }


  @include all-phone {
    margin: 0;
    background: $light-black;
    grid-template-columns: 1fr 130px;
    padding: ($module-padding / 1.5) $module-padding; }


  &_left {
    @include flexbox;

    padding-right: $module-padding;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 200;

    @include all-phone {
      align-items: flex-end;
      text-align: right; } }

  &_q {
    display: block;
    font-size: 1rem;

    @include all-phone {
      font-size: 1rem; } }

  &_markdown {
    display: inline;

    p {
      display: inline; } }

  &_figure {
    margin: 0;
    padding: 0;
    width: 150px;
    height: 150px;
    overflow: hidden;

    @include all-phone {
      width: 100px;
      height: 100px; } }

  &_img {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    object-fit: contain; }

  &_cite {
    margin-top: 1rem;
    font-size: 1.2rem;
    text-decoration: none;
    font-style: normal;
    display: block;
    font-weight: 300;

    @include all-phone {
      margin-top: 0.75rem;
      font-size: 1rem; } } }
