@import "../../sass/global";

.pageLayout {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-row-gap: 3rem;
  grid-column-gap: 3rem;
  margin: 2rem 6rem;

  @include all-mobile {
    grid-row-gap: 0;
    grid-template-columns: repeat(1, 1fr); }


  @include tablet {
    width: 60%;
    margin: 1rem 2rem;
    margin-left: auto; }


  @include all-phone {
    margin: 0;
    margin-top: 80px; } }
