@import "../../sass/global";

.mobile-menu,
.menu {
  @include flexbox;

  width: 100%;
  align-items: center;
  justify-content: center; }

.menu {
  box-sizing: border-box;
  margin-top: 10px;
  padding: 25px 0 15px 0;
  justify-content: space-between;
  border-top: rgba(255, 255, 255, 0.6) 1px solid;

  .menu-item {
    // padding: 0 1%
    @include flexbox;

    align-items: center;
    justify-content: center;
    font-size: 1.1em;

    .fa-stack-1x {
      line-height: 45px; } } }

.mobile-menu {
  box-sizing: border-box;
  padding: 0 0 20px 0;
  flex-direction: row;
  background: rgba(22, 22, 22, 0.95);
  position: fixed;
  top: 80px;
  z-index: 2;

  @include transition(0.5s ease opacity);

  .menu-item {
    font-size: 1em;
    width: 90%;

    @include flexbox;

    align-items: center;
    justify-content: center;
    text-decoration: none;

    @include transition(0.25s color ease);

    cursor: pointer; }

  .menu-item:hover {
    color: rgba(255, 250, 205, 0.9); } }
