@import "../../sass/global";

.header,
.mobile-header {
  font-weight: 100;
  background: rgba(22, 22, 22, 0.8);

  @include flexbox;

  flex-direction: column;
  align-items: center;

  @include all-phone {
    background: rgba(22, 22, 22, 0.95); } }

.header {
  padding: 0 $module-padding; }

.mobile-header {
  flex-direction: row; }

.title-text {
  font-weight: 100;
  font-size: 4.5em;

  @include flexbox;

  justify-content: space-between;
  width: 100%;
  text-transform: uppercase;

  @include laptop {
    font-size: 4em; }


  @include tablet {
    font-size: 2.5em; } }

.title {
  width: 98.5%;
  padding: 15px 0 5px 0; }

.mobile-header {
  @include flexbox;

  width: 100%;
  padding: 40px 20px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 1;
  height: 50px;
  justify-content: space-between;

  .title {
    width: 90%;
    padding: 5px 10px;

    @include all-phone {
      width: 85%; } }

  .title-text {
    font-weight: 100;
    width: 100%;
    font-size: 2em; } }

.hamburger {
  cursor: pointer; }
