@import "../../sass/global";

table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse; }

th,
td {
  text-align: left;
  height: 60px;
  border-bottom: rgba(255, 255, 255, 0.6) 1px solid;
  padding: 0 10px;
  font-size: 90%;

  @include all-mobile {
    font-size: 75%; } }

.calendar-label {
  text-align: center;
  font-weight: 200; }

.calendar-entry-featured {
  font-weight: 400;
  background: rgba(220, 200, 170, 0.2); }
