@import "../../sass/global";

body {
  margin: 0;
  padding: 0;
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 300;

  @include desktop {
    font-weight: 100; }


  @include laptop {
    font-weight: 100; }


  height: 100%;
  line-height: 1.2; }

#root:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url("./bg.jpeg") no-repeat top (left / cover); }

a {
  color: rgba(220, 200, 170, 1);

  @include transition(color 0.5s ease);

  text-decoration: none; }

a:visited {
  color: rgba(220, 200, 170, 1);

  @include transition(color 0.5s ease); }

a:hover {
  color: rgba(255, 250, 205, 1);

  @include transition(color 0.5s ease); }

b {
  font-weight: 600; }

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500; }

.loading {
  @include flexbox;

  justify-content: center;
  align-items: center; }
