@import "../../sass/global";

.video {
  width: 49%;

  @include all-phone {
    width: 100%; }


  height: 100%;
  margin: 0 auto; }

.wide-video {
  width: 100%; }

.h_iframe {
  position: relative;
  padding-top: 56%; }

.h_iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
