@import "../../sass/global";

.content {
  background: rgba(22, 22, 22, 0.8);

  @include all-phone {
    width: 100%;
    background: rgba(22, 22, 22, 0.6); } }

.main {
  padding: $module-padding;
  width: 100%;
  min-height: 50vh;

  @include all-phone {
    min-height: 70vh; } }
