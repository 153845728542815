@import "../../sass/global";

.home {
  box-sizing: border-box;

  @include flexbox;

  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
