@import "../../sass/global";

$form-width: 100%;
$form-item-height: 40px;
$label-width: 90px;
$text-entry-width: calc(100% - 80px);

$light-yellow: rgba(220, 200, 170, 0.6);
$hover-yellow: rgba(255, 250, 205, 0.5);
$focus-yellow: rgba(255, 250, 205, 0.5);
$lighter-grey: rgba(200, 200, 200, 1);
$transparent-white: rgba(255, 255, 255, 0.5);
$dark-black: rgba(22, 22, 22, 0.95);
$transparent: rgba(0, 0, 0, 0);

$border-radius: 6px;
$font-size: 16px;
$small-font-size: 14px;

$form-font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Roboto", "Helvetica Neue", Helvetica, sans-serif;

.entry-form {
  width: $form-width;

  @include flexbox;

  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between; }

.form-item {
  // background: $transparent-white
  position: relative;

  @include flexbox;

  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: $form-item-height;
  margin-bottom: 20px;

  div {
    @include flexbox;

    align-items: center;
    height: 100%;
    width: $label-width;
    padding: 0 12px;
    box-sizing: border-box;
    background: $light-yellow;
    border: 1px solid $transparent;
    border-radius: $border-radius;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: $font-size;
    font-weight: 300;

    @include all-phone {
      font-size: $small-font-size; } }

  input,
  select,
  textarea {
    background: $transparent-white;
    font-family: $form-font-family;
    font-weight: 300;
    display: block;

    @include flexbox;

    align-items: center;
    height: 100%;
    width: $text-entry-width;
    box-sizing: border-box;
    border: 1px solid $transparent;
    border-radius: $border-radius;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    font-size: $font-size;

    @include all-phone {
      font-size: $small-font-size; }


    margin: 0;

    @include transition(0.25s ease all); }

  input,
  textarea {
    padding: 0 12px; }

  input:focus,
  select:focus {
    outline: none;
    border-color: $focus-yellow;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $focus-yellow; } }

.form-email {
  div {
    width: 140px; } }

.form-buttons {
  input {
    margin: 20px 15px 0 0;
    font-size: 18px;
    height: $form-item-height;
    border: none;
    border-radius: 6px;
    padding: 2px 18px 0 18px;
    background: $light-yellow;
    cursor: pointer;

    @include transition(0.25s ease background); }

  input:hover {
    background: $hover-yellow; }

  input:focus {
    outline: none;
    box-shadow: 0 0 8px $hover-yellow; } }

.error,
.message {
  font-size: 12px;
  font-style: italic; }

.message {
  color: white; }

.error {
  color: white; }

.edited-at {
  margin: 20px 0 0 0; }

.post-box {
  width: $form-width;
  box-sizing: border-box;
  border-radius: $border-radius;
  height: 320px;
  padding: 12px 18px;
  font-size: $font-size;
  margin: 0 0 15px 0; }

textarea {
  background: $transparent-white;
  resize: vertical;
  font-family: $form-font-family;
  border-radius: $border-radius;
  height: 200px !important;
  width: $text-entry-width;
  box-sizing: border-box;
  border: 1px solid $transparent;
  border-radius: $border-radius;
  font-size: $font-size;
  margin: 0;
  padding: 8px 12px;
  font-weight: 300;
  width: 100%;

  @include all-phone {
    font-size: $small-font-size; } }

textarea:focus {
  outline: none;
  border-color: $focus-yellow;
  box-shadow: 0 0 8px $focus-yellow; }

textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder {
  color: white !important; }

textarea:required {
  box-shadow: none; }
