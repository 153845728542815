@import "../../sass/global";

.footer {
  background: rgba(22, 22, 22, 0.8);
  margin: 0 0 60px 0;
  padding: 30px 0 25px 0;

  @include flexbox;

  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include all-phone {
    margin: 0;

    padding 10px 0 {}

    background: rgba(22, 22, 22, 0.95); }


  p {
    margin: 0 0 12px 0; } }

.footer-text {
  font-size: 0.6em;
  font-style: italic; }

.site-map {
  margin: 0 0 12px 0;

  @include flexbox;

  flex-wrap: wrap;
  justify-content: center; }

.site-map-item {
  cursor: pointer;
  margin: 0 5px; }

.site-map-item:hover {
  color: rgba(255, 250, 205, 1); }
